import React, { Component } from 'react'
import PageList from './components/page-list'
import PageEditor from './components/page-editor'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import {Context} from './api'
import './App.css'

class App extends Component {

  render () {
    return (
      <Context.Provider value={this.props}>
        <BrowserRouter>
          <Switch>
            <Route exact path='/' component={PageList} />
            <Route path='/page/:pageid/edit' component={PageEditor} />
          </Switch>
        </BrowserRouter>
      </Context.Provider>
    )
  }
}

export default App
