import 'babel-polyfill'
import 'whatwg-fetch'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import Loading from './loading'
import Login from './login'
import * as API from './api'
import * as serviceWorker from './serviceWorker'
import mixpanel from './utils/analytics'

export function start () {
  API.getUser()
  .catch(error => {
    console.error(error)
    ReactDOM.render(<Login />, document.getElementById('root'))
  })
  .then(user => {
    if (!user) {
      ReactDOM.render(<Login />, document.getElementById('root'))
    } else {
      if (mixpanel) {
        mixpanel.register({
          $email: user.user.email,
          Email: user.user.email,
          Accounts: user.user.accounts,
        })
        mixpanel.identify(user.user.email)
        mixpanel.people.set_once({
          $email: user.user.email,
          Email: user.user.email,
          Accounts: user.user.accounts,
        })
      }
      ReactDOM.render(<App user={user} />, document.getElementById('root'))
    }
  })
  ReactDOM.render(<Loading />, document.getElementById('root'))
}
start()
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
