import mixpanel from 'mixpanel-browser'

mixpanel.init('acf70a532adc195abbec94a936ec8dc4', { opt_out_tracking_by_default: false })

export default mixpanel

export function logoutClicked () {
  mixpanel.track('Logout')
}

export function loginEmailSent(email){
  mixpanel.track('Login Email Sent', { email })
}

export function loginEmailFailure (email) {
  mixpanel.track('Login Email Failure', { email })
}

export function addressSearch (addr) {
  mixpanel.track('Searched by Search Bar', { searchAddress: addr })
}

export function pageViewed (page) {
  mixpanel.track('Page Viewed', page)
}

export function pageUpdated (page) {
  mixpanel.track('Page Updated', page)
}

export function pageUpdateFailed (page) {
  mixpanel.track('Page Update Failed', page)
}

export function searched (term) {
  mixpanel.track('Searched', term)
}