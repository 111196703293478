import React from 'react'
import SearchIcon from './search-icon'
import './neighborhood-filter.css'

export default ({ filter, handleFilterChange, placeholder = 'Search' }) => (
  <section>
    <div className='neighborhood-container'>
      <input
        className='neighborhood-input'
        type='text'
        placeholder={placeholder}
        onChange={handleFilterChange}
      />
      <SearchIcon />
    </div>
  </section>
)
