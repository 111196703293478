import React, { Component } from 'react'
import './header.css'
import * as API from '../../api'
import LiveByLogo from './liveby-logo.png'
import { start } from '../../index'
import { getPageCount } from '../../api'
import mixpanel, { logoutClicked } from '../../utils/analytics'
export default class Header extends Component {

  constructor () {
    super()
    this.state = {
      pageCount: null
    }
    this.getPageCount()
  }
  getPageCount = async () => {
    const count = await getPageCount()
    this.setState({
      pageCount: count
    })
  }

  logout = async () => {
    logoutClicked()
    mixpanel.reset()
    await API.logout()
    start()
  }
  shouldComponentUpdate(props, state) {

    return this.state.pageCount !== state.pageCount
  }


  render () {
    const {pageCount} = this.state
    return (
      <section className='top'>
        <img className='liveby-logo' src={LiveByLogo} alt='LiveBy' />
        {pageCount != null && (<span>Total Pages: {pageCount}</span>)}
        <button className='btn logout' onClick={this.logout}>Logout</button>
      </section>
    )
  }
}
