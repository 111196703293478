import React, { Component } from 'react'
import classnames from 'classnames'
import startCase from 'lodash.startcase'
import debounce from 'lodash.debounce'

import * as API from '../api'
import Loading from '../loading'
import PagesFilter from './page-filter'

import { Link } from 'react-router-dom'
import {Context} from '../api'
// import PagesFilter from './page-filter'


import BioIcon from './page-icons/bio'
import PhotoIcon from './page-icons/photo'
import Header from './header'
import './pages.css'
import mixpanel, { searched, pageViewed } from '../utils/analytics'

export function getAccountPages () {
  return []
}

export default class PageList extends Component {
  state = {
    loading: true,
    pages: [],
    filter: ''
  }
  componentDidMount () {
    this.getPages()
    pageViewed({ Type: 'Page List' })
  }

  async getPages () {
    API.getAccountPages((pages) => {
      this.setState((st) => ({
        pages: [...st.pages, ...pages],
        loading: pages.length >= 100
      }))
    }, 100)
  }

  onFilterChange = (evt) => {
    const {value} = evt.target
    this.setFilter(value)
  }
  setFilter = debounce((value) => {
    this.setState({
      filter: value
    })
    if(value){
      searched({SearchTerm: value})
    }
  }, 300)


  render () {
    const {loading, pages, filter} = this.state
    // if(loading) {
    //   return (
    //     <Loading />
    //   )
    // }
    return (
      <div className='brokerage-preview'>
        <Header />
        <PagesFilter
          placeholder='Search Pages'
          handleFilterChange={this.onFilterChange}
        />
        <ul className='pages-list'>
            {pages
              .filter(p => p.status !== 'archived')
              .filter(page => (
                !filter || 
                ~`${page.name}, ${page.city}, ${page.state}`.toLowerCase().indexOf(filter.toLowerCase()) ||
                ~(page.url || '').toLowerCase().indexOf(filter.toLowerCase())
              )).map(page => (
                <PageSummaryView
                  key={page._id}
                  page={page}
                  url={`/page/${page._id}/edit`}
                />
              ))}
            {loading && <Loading />}
          </ul>
      </div>
    )
  }
}
PageList.contextType = Context

class PageSummaryView extends Component {
  shouldComponentUpdate(props) {
    return this.props.page._id !== props.page._id
  }
  render() {
    const { page, url, selected } = this.props
    return (
      <li
        className={classnames('page', page.status || 'published', selected && 'selected')}
      >
        <Link to={url}>
          <span>
            {(page.name === page.city) ? page.name : page.name + ' ' + page.city}, {page.state}
          </span>
          <span>
            <span className='page-info'>{page.pageType ? startCase(page.pageType) : 'Neighborhood'}</span>
            <BioIcon className='bio' fillColor={page.bio ? '#000' : '#DDD'} />
            <PhotoIcon className='photo' fillColor={page.banner_16x9 ? '#000' : '#DDD'} />
          </span>
        </Link>
      </li>
    )
  }
}