import React, { Component } from 'react'
import PlusSVG from './page-icons/edit-icon'

import classNames from 'classnames'
import Dropzone from 'react-dropzone'
import Select from 'react-select'
import 'react-select/dist/react-select.css'

import capitalize from '../capitalize'
import States from '@liveby/states-convert'

import { Link } from 'react-router-dom'
import startCase from 'lodash.startcase'

import './page-editor.css'
import { Context, uploadImage, getPage, savePage } from '../api'
import Jimp from 'jimp/es'

import Loading from '../loading'
import { pageViewed, pageUpdated, pageUpdateFailed } from '../utils/analytics'

import Modal from 'react-modal';
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'

const PAGE_PROPS = ['name', 'city', 'state']
const states = States('name', 'abbr')

class PageEditor extends Component {

  state = {
    page: null,
    saving: false,
    saved: false,
    banner: {img: null, alt: null},
    photos_description: null,
    photoLibrary: [],
    pages: [],
    childPages: [],
    selectedChildPages: [],
    uploadingBanner: false,
    uploadingImages: false,
    altModal: false,
    altSelected: null,
    altText: '',
    bio: ''
  }

  childPageEditor = React.createRef()

  async componentDidMount() {
    const id = this.props.match.params.pageid
    const page = await getPage(id)
    this.setState({
      page,
      childPages: (page.child_pages || [])
        .filter(page => page && page.status !== 'archived'),
      bio: page.bio
    })

    if (page.banner_img_16x9 && page.banner_img_16x9.img) {
      this.setState({
        banner: page.banner_img_16x9
      })
    } else if (page.banner_16x9) {
      this.setState({
        banner: { img: page.banner_16x9, alt: '' }
      })
    }
    if (page.photo_library && page.photo_library.length > 0) {
      this.setState({
        photoLibrary: this.state.page.photo_library
      })
    } else if (page.photos) {
      const photoLibrary = this.state.page.photos.map((photo) => ({ img: photo, alt: '' }))
      this.setState({
        photoLibrary: photoLibrary
      })
    }
    pageViewed({ Type: 'Edit', ID: id, Name: page.name, 'Boundary Type': page.pageType, State: page.state, City: page.city })
  }

  handleRemoveImageClick = (evt) => {
    evt.preventDefault()
    this.setState({
      banner: {img: null, alt: this.state.banner.alt}
    })
  }

  handleRemoveGalleryImageClick = (idx) => {
    if (window.confirm('Are you sure you want to remove this image?')) {

    const photos = this.state.photoLibrary
    this.setState({
      photoLibrary: [
        ...photos.slice(0, idx),
        ...photos.slice(idx + 1)
      ]
    })
  }
}

  upload = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onerror = (e) => {
        reader.abort()
        reject(e)
      }
      reader.onloadend = async () => {
        try {
          const resized = await this.resize({
            size: file.size,
            file: reader.result
          })
          const { url } = await uploadImage(file, resized)
          this.setState(({photos, page}) => ({
            photoLibrary: [...(this.state.photoLibrary), {img: url, alt: ''}]
          }))
          resolve(url)
        } catch (err) {
          reject(err)
        }
      }
      reader.readAsDataURL(file)
    })
  }

  handlePhotosChange = async (files) => {

    this.setState({uploadingImages: true})
    for (let file of files) {
      try {
        await this.upload(file)
      } catch (err) {
        console.error(err)
      }
    }
    this.setState({uploadingImages: false})

  }

  resize = async ({ size, file }) => {
    // Do nothing if the file size is less than 500 KB
    if (size < 512000) return file

    const image = await Jimp.read(file)
    const resized = await image
      .resize(1920, Jimp.AUTO)
      .quality(80)
      .getBase64Async(Jimp.AUTO)

    return resized.length < file.length
      ? resized
      : file
  }

  handleBannerImageChange = (acceptedFile) => {
    this.setState({uploadingBanner: true})
    const file = acceptedFile[0]
    const reader = new FileReader()

    reader.onerror = (e) => {
      reader.abort()
      console.error(e.message)
      this.setState({uploadingBanner: false})
    }

    reader.onloadend = async () => {
      const resized = await this.cropAndResize({ size: file.size, file: reader.result })

      uploadImage(file, resized)
        .then(({ url }) => {
          this.setState({ banner: {img: url, alt: this.state.banner.alt}, uploadingBanner: false })
        })
        .catch(e => {
          console.error(e)
          this.setState({uploadingBanner: false})
        })
    }
    reader.readAsDataURL(file)
  }

  cropAndResize = async ({ size, file }) => {
    // Do nothing if the file size is less than 500 KB
    if (size < 512000) return file

    const image = await Jimp.read(file)
    const resized = await image
      // .resize(1440, Jimp.AUTO)
      .cover(1920, 1080)
      .quality(80)
      .getBase64Async(Jimp.AUTO)

    return resized.length < file.length
      ? resized
      : file
  }

  makeObjectFromForm(form) {
    return PAGE_PROPS.reduce((o, k) => {
      return {
        ...o,
        [k]: form.elements.namedItem(k).value
      }
    }, {})
  }

  getOtherInputs = (form) => {
    const { banner, photos_description, photoLibrary, childPages, bio } = this.state

    return {
      banner_img_16x9: banner,
      ...({ photos_description }),
      photo_library: [...photoLibrary ],
      ...(childPages ? { child_pages: childPages.map(p => p._id) } : {}),
      bio: bio
    }
  }

  mixpanelLogWhatChanged = (formInfo) => {
    let changes = []
    if (formInfo.banner_16x9 !== this.state.page.banner_16x9) {
      changes.push('Banner')
    }
    if (formInfo.bio !== this.state.page.bio) {
      console.log("bioUpdated")
      changes.push('Bio')
    }
    if (formInfo.city !== this.state.page.city) {
      changes.push('City')
    }
    if (formInfo.state !== this.state.page.state) {
      changes.push('State')
    }
    if (formInfo.name !== this.state.page.name) {
      changes.push('Name')
    }
    if (formInfo.photos_description !== this.state.page.photos_description) {
      changes.push('Photo Description')
    }
    pageUpdated({ Changes: changes })
  }

  handleSave = async (evt) => {
    evt.preventDefault()
    // Get Inputs to set
    const textInputs = this.makeObjectFromForm(evt.target)
    const photoInputs = this.getOtherInputs(evt.target)
    const inputs = { ...textInputs, ...photoInputs }


    // Make sure state is 2 characters
    if(inputs.state && inputs.state.length > 2) {
      inputs.state = states(capitalize(inputs.state))
      if(!inputs.state) {
        return this.setState({
          error: 'State must be a valid two character state code'
        })
      } else {
        document.querySelector('input[name="state"]').value = inputs.state
      }
    }

    // Send save to DB
    this.setState({ saving: true, error: null })
    try {
      const page = await savePage(this.state.page._id, inputs)
      //Set new page state
      this.mixpanelLogWhatChanged(inputs)
      this.setState({ page, saving: false, saved: true })
      setTimeout(() => this.setState({saved: false}), 1000)
    } catch(e) {
      pageUpdateFailed({ ID: this.state.page._id, Name: inputs.name, State: inputs.state, City: inputs.city })
      console.error(e)
    }
    this.setState({ saving: false })

  }

  handleAltClick = (idx) => {
    const photos = this.state.photoLibrary
    this.setState({
      altModal: true,
      altSelected: idx,
      altText: (photos && photos[idx].alt) ? photos[idx].alt : ''
    })

  }

  handleAltSave = () => {
    const photos = this.state.photoLibrary
    const photoObject = {
      img: photos[this.state.altSelected].img,
      alt: this.state.altText
    }

    photos[this.state.altSelected] = photoObject

    this.setState({
      altModal: false,
      photoLibrary: photos
    })
  }

  setBio=(bio) => {
    this.setState({bio})
  }

  render() {
    const { page, error, saving, saved, banner, photos, uploadingBanner, uploadingImages } = this.state

    if (page === null) return <Loading />

    return (
      <div className='page-editor'>
        <div>
          <Link className='back-to-pages' to={`/`}>Back To Pages</Link>
        </div>
        <form onSubmit={this.handleSave}>
          <div className='column-1'>
            {page.url && <section>
                <h3>Live Page URL</h3>
                <span className='live-url'>
                  /{page.url}
                </span>
              </section>}
            {PAGE_PROPS.map(prop => (
              <section key={prop} >
                <h3>
                  {startCase(prop.replace('_', ' '))}
                </h3>
                <input type='text' name={prop} defaultValue={page[prop]} />
              </section>
            ))}
            <section>
              <h3>Bio</h3>
              <QuillToolbar />
              <ReactQuill 
                className='bio-editor' 
                name='bio' 
                theme='snow' 
                value={this.state.bio} 
                onChange={(bio) => this.setBio(bio)}
                placeholder={"This location is awesome because..."}
                modules={modules}
                formats={formats}
              />
            </section>
            <section>
              <h3>Banner Image</h3>
              {uploadingBanner ? (
                <div className='content-width'>
                  <Loading />
                </div>
              ) : (
                <>
                  <ImageUploader
                    image={this.state.banner.img}
                    handleImageChange={this.handleBannerImageChange}
                    handleRemoveImage={this.handleRemoveImageClick}
                    warning
                    id='banner'
                  />
                  <h3>Banner Alt Text</h3>
                  <input type='text' name='banner-alt-text' onChange={(e) => {this.setState({
                    banner: {img: this.state.banner.img, alt: e.target.value }
                  })}} value={this.state.banner.alt} />
                </>
              )}
            </section>
            <section>
              <h3>Image Gallery</h3>
              <span className='helptext'>Description (optional)</span>
              <textarea name='photos-description' defaultValue={page.photos_description} onChange={({ target }) => this.setState({ photos_description: target.value })} />
              <span className='helptext'>images</span>
              <div style={{ display: 'flex', flexFlow: 'wrap' }}>
                {this.state.photoLibrary.slice(0, 20).map((photo, idx) => (
                  <ImageUploader
                    key={idx}
                    handleRemoveImage={() => this.handleRemoveGalleryImageClick(idx)}
                    className='photo-gallery'
                    image={photo.img}
                    handleAltClick={() => this.handleAltClick(idx)}
                    alt={(photo.alt) ? photo.alt : '' }
                    id='photos'
                  />
                ))}
                {uploadingImages
                  ? (<Loading />)
                  : (<ImageUploader className='photo-gallery' handleImageChange={this.handlePhotosChange} />
                )}
              </div>
              <div>
                <button className='save-button' disabled={saving || uploadingBanner || uploadingImages }>Save</button>
                {saved && (
                  <span style={{paddingLeft: '1em'}}>Saved!</span>
                )}
                {error && (
                  <span style={{paddingLeft: '1em'}}>{error}</span>
                )}
              </div>
            </section>
            <br /><br />
          </div>
        </form>

        <Modal 
          onRequestClose={() => {this.setState({ altModal: false })}}
          isOpen={this.state.altModal} style={{
            overlay: {
              backgroundColor: 'rgb(100,100,100,0.5)'
            },
            content: {
              align: 'center',
              margin: 'auto',
              width: '600px',
              height: 'fit-content'
            },
            autoFocus: false
          }}>
          <div className='alt-text-header'>
            <a onClick={() => this.setState({altModal: false})}>X</a>
          </div>
          <div className='alt-text-body page-editor'>
            <div className='alt-text-image'>
              <img src={this.state.photoLibrary && this.state.photoLibrary[this.state.altSelected] && this.state.photoLibrary[this.state.altSelected].img} />
            </div>
            <br />
            <label>Alt Text</label><br />
            <input type="text"
              autoFocus='true'
              id='altTextField'
              value={this.state.altText}
              onChange={(e) => this.setState({altText: e.target.value})}
            />
          </div>
          <div className='alt-text-buttons page-editor'>
            <button
              className='save-button'
              onClick={this.handleAltSave}
            >Save</button>
          </div>
        </Modal>
      </div>
    )
  }
}
PageEditor.contextType = Context

export default PageEditor

const ImageUploader = (props) => (
  props.image
    ? (
      <div className='image-alt-container'>
        <div className={['image-preview-container', props.className || ''].join(' ')}>
          <div onClick={props.handleRemoveImage}>
            <PlusSVG />
            <img className='image-preview' src={props.image} alt={props.alt || ''} />
          </div>
        </div>
        {props.handleAltClick &&
            <a className='alt-text-link' onClick={props.handleAltClick}>edit alt text</a>}
      </div>
    )
    : (
        <div className={props.className || ''}>
        <Dropzone
          onDrop={props.handleImageChange}
          accept='image/png, image/jpg, image/jpeg'
          activeClassName='allowed'
          rejectClassName='rejected'
        >
          {({getInputProps, getRootProps, isDragActive, isDragReject}) => (
            <div className={classNames('image-placeholder', {'allowed': isDragActive,'rejected': isDragReject})} {...getRootProps()}>
              <p>+</p>
              <input {...getInputProps()} />
            </div>
          )}
        </Dropzone>
      </div>
    )
)


// Quill Toolbar component
export const QuillToolbar = () => (
  <div id="toolbar">
    <span className="ql-formats">
      <select className="ql-header" defaultValue="3">
        <option value="1">Heading</option>
        <option value="2">Subheading</option>
        <option value="3">Normal</option>
      </select>
    </span>
    <span className="ql-formats">
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />
      <button className="ql-strike" />
    </span>
    <span className="ql-formats">
      <button className="ql-list" value="ordered" />
      <button className="ql-list" value="bullet" />
      <button className="ql-indent" value="-1" />
      <button className="ql-indent" value="+1" />
      <select className="ql-align" />
    </span>
    <span className="ql-formats">
      <button className="ql-blockquote" />
      <button className="ql-link" />
      <button className="ql-image" />
    </span>
  </div>
);
export const modules = {
  toolbar: {
    container: "#toolbar",
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true
  }
};

// Formats objects for setting up the Quill editor
export const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  "script",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];