import React from 'react'
export default ({fillColor, className}) => (
  <svg className={className} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 278 278'>
    <g id='Layer_2' data-name='Layer 2'>
      <g id='Layer_1-2' data-name='Layer 1'>
        <path style={{fill: fillColor || '#fff'}} d='M278,278H0V0H278ZM14,264H264V14H14Z' />
        <polygon style={{fill: fillColor || '#fff'}} points='225.19 277.624 7 277.624 7 263.624 197.247 263.624 94.895 126.692 12.607 236.78 1.393 228.399 94.895 103.308 225.19 277.624' />
        <polygon style={{fill: fillColor || '#fff'}} points='265.393 237.784 208.83 162.111 169.558 214.65 158.344 206.268 208.83 138.727 276.607 229.403 265.393 237.784' />
        <rect style={{fill: fillColor || '#fff'}} x='208.82959' y='263.49902' width='62.17041' height='14' />
        <path style={{fill: fillColor || '#fff'}} d='M164,122.5A42.5,42.5,0,1,1,206.5,80,42.54815,42.54815,0,0,1,164,122.5Zm0-71A28.5,28.5,0,1,0,192.5,80,28.53242,28.53242,0,0,0,164,51.5Z' />
      </g>
    </g>
  </svg>
)
