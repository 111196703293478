import React, {Component} from 'react'
import LiveByLogo from './components/header/liveby-logo.png'
import './components/header/header.css'
import './App.css'
import {login} from './api'
import Loading from './loading'
import { pageViewed, loginEmailSent, loginEmailFailure } from './utils/analytics';

export default class Login extends Component {
  constructor() {
    super()
    this.state = {
      error: false,
      loading: false,
      sent: false,
      failed: !!this.getSearchOptions().failed
    }
    pageViewed({ Type: 'Login' })
  }

  login = async (evt) => {
    evt.preventDefault()
    evt.stopPropagation()
    this.setState({
      error: false,
      loading: true,
      sent: false,
      failed: false
    })
    const email = evt.target['email'].value
    try {
      await login(email)
      this.setState({
        error: false,
        loading: false,
        sent: true
      })
      loginEmailSent({ Email: email })
      setTimeout(() => this.setState({sent: false}), 10000)
    } catch (e) {
      loginEmailFailure({ Email: email })
      this.setState({
        error: email,
        loading: false,
        sent: false
      })
    }
  }

  getSearchOptions() {
    const someArray = document.location.search.slice(1).split('=')
    const newArray = [], size = 2
    while (someArray.length > 0) newArray.push(someArray.splice(0, size))
    return newArray.reduce((o, [k,v]) => ({...o, [k]: v}), {})
  }
  render () {
    const {error, loading, sent, failed} = this.state
    return (
      <main className='login'>
        <img className='liveby-logo' src={LiveByLogo} alt='LiveBy' />
        <section className='login-form'>
          {loading && <Loading />}
          {!loading && !sent && (
            <form onSubmit={this.login}>
              <label>
                Email
                <input type='text' name='email' />
                <span className='focus-border' />
              </label>
              <input className='btn' type='submit' value='Login' />

            </form>
          )}
          {error && (
            <div className='error'>
              The email is not a registered LiveBy account.
              <br/>
              Contact <a href={`mailto:support@liveby.com?subject=LiveBy account for ${error}`}>support@liveby.com</a> for an account.
            </div>
          )}
          {sent && <SentMessage />}
          {failed && (
            <div>
              The login link has expired. Please log in again.
            </div>
          )}
        </section>
      </main>
    )
  }
}

const SentMessage = () => (
    <div className='sent'>
      Your login details have been emailed to you.
    </div>
)
