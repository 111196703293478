import React from 'react'
export default () => (
  <svg className='search-icon' width='34' height='35' viewBox='0 0 34 35'>
    <g fill='none' fillRule='evenodd'><path d='M21.074 19.87c-.52.64-1.103 1.225-1.743 1.743l2.6 2.6 1.743-1.744-2.6-2.6z' fill='#8F91A0' />
      <g transform='translate(0 .438)'>
        <path d='M2.16 11.966c0-5.35 4.352-9.705 9.703-9.705 5.352 0 9.706 4.355 9.706 9.706 0 5.352-4.355 9.705-9.707 9.705-5.35 0-9.704-4.352-9.704-9.704m18.914 7.467a11.805 11.805 0 0 0 2.653-7.467c0-6.542-5.322-11.864-11.864-11.864C5.323.102 0 5.424 0 11.966 0 18.508 5.322 23.83 11.863 23.83c2.83 0 5.427-.996 7.468-2.654.64-.52 1.225-1.103 1.744-1.743' fill='#D2D1D3' />
      </g>
      <path d='M33.784 30.89l-8.99-8.99a.39.39 0 0 0-.55 0l-.57.57-1.744 1.743-.57.57a.387.387 0 0 0 0 .55l8.99 8.99a.388.388 0 0 0 .55 0l2.884-2.884a.39.39 0 0 0 0-.55' fill='#D2D1D3' />
      <path d='M0 23.83V.1h23.727V23.83z' />
    </g>
  </svg>
)
