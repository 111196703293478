/* global fetch */
import React from 'react'

export const BASE_URL = (/.*\.liveby\.com/.test(window.location.host))
? 'https://' + window.location.host.replace('portal-beta', 'console.api')
: 'http://localhost:3001'

export const Context = React.createContext({
  user: null
})

export async function getUser () {
  const res = await fetch(`${BASE_URL}/user`, {
    credentials: 'include',
    mode: 'cors'
  })

  if (!res.ok) {
    return null
  }

  const account = await res.json()
  return account
}

export async function getAccount (shortname) {
  const res = await fetch(`${BASE_URL}/account?account=${shortname}`, {
    credentials: 'include',
    mode: 'cors'
  })

  if (!res.ok) {
    return null
  }

  const account = await res.json()
  return account
}

export async function login (email) {
  const res = await fetch(`${BASE_URL}/login/email`, {
    credentials: 'include',
    mode: 'cors',
    method: 'POST',
    body: JSON.stringify({
      email
    })
  })

  if (!res.ok) {
    return null
  }

  const response = await res.json()
  if (response.status !== 'ok') {
    throw new Error('send failed')
  }
  return response
}

export async function logout () {
  const res = await fetch(`${BASE_URL}/logout`, {
    credentials: 'include',
    mode: 'cors',
    method: 'GET'
  })

  if (!res.ok) {
    return null
  }

  const response = await res.json()
  return response
}

export async function getPageCount () {
  try {
    const res = await fetch(`${BASE_URL}/pages/count`, {
      credentials: 'include',
      mode: 'cors',
      method: 'GET'
    })

    if (!res.ok) {
      return null
    }

    const response = await res.json()
    return response.count
  } catch (e) {
    return null
  }
}

export async function getAccountPages (cb, limit = 100) {
  let page = 0
  let result = []

  try {
    do {
      const res = await fetch(`${BASE_URL}/pages?page=${page}&limit=${limit}`, {
        credentials: 'include',
        mode: 'cors'
      })
      result = await res.json()
      page++
      cb(result)
    } while (result.length >= limit)
  } catch (err) {
    console.log(err)
    cb(result)
  }
}

export async function getPage (id) {
  const res = await fetch(`${BASE_URL}/pages/get?id=${id}`, {
    mode: 'cors',
    credentials: 'include'
  })
  if (!res.ok) {
    return this.setState({ error: await res.text() })
  }
  const page = await res.json()
  return page
}

export async function uploadImage (file, body) {
  const res = await fetch(`${BASE_URL}/image`, {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    body: JSON.stringify({
      name: file.name.replace(/[^\w.]+/g, '-'),
      img: body
    })
  })
  return await res.json()
}

export async function savePage (page, inputs) {
  const res = await fetch(`${BASE_URL}/pages/update?id=${page}`, {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    body: JSON.stringify({ set: inputs })
  })
  if (!res.ok) {
    throw new Error(await res.text())
  }
  return res.json()
}
