import React from 'react'
export default ({fillColor, className}) => (
  <svg className={className} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 264 277'>
    <g>
      <g>
        <rect style={{fill: fillColor || '#fff'}} width='264' height='14' />
        <rect style={{fill: fillColor || '#fff'}} y='61.6001' width='264' height='14' />
        <rect style={{fill: fillColor || '#fff'}} y='123.2002' width='264' height='14' />
        <rect style={{fill: fillColor || '#fff'}} y='184.80029' width='264' height='14' />
        <rect style={{fill: fillColor || '#fff'}} y='246.40039' width='133' height='14' />
      </g>
    </g>
  </svg>
)
