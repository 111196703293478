import React, {Component} from 'react'

export default class Loading extends Component {
  hyp = (size) => {
    return Math.sqrt((size * size) + (size * size))
  }
  render () {
    const {size = 65} = this.props
    return (
      <div className='loading'>
        {/* Use hypotenuse for height to avoid bouncing */}
        <div style={{height: this.hyp(65) + 'px'}}> 
          <svg 
            className='spinner' 
            width={`${size}px`} 
            height={`${size}px`} 
            viewBox='0 0 66 66' 
            xmlns='http://www.w3.org/2000/svg'
          >
            <circle 
              className='path' 
              fill='none' 
              strokeWidth='6' 
              strokeLinecap='round' cx='33' cy='33' r='30' />
          </svg>
        </div>
      </div>
    )
  }
}
